export function capitalize(string: string): string {
  return string.substring(0, 1).toUpperCase() + string.slice(1).toLowerCase();
}

export function toLabel(string: string): string {
  return capitalize(string).split(/-|_/).join(" ");
}

export function generateUrlFriendlyString(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export function hex2rgb(hex: string) {
  if (hex.startsWith("#")) {
    hex = hex.slice(1);
  }

  switch (hex.length) {
    case 3:
      return shortHex(hex);
    case 6:
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      return [r, g, b];

    default:
      console.error(`${hex.length} characters is not a valid hex code.`);
      return [];
  }

  function shortHex(hex: string) {
    const rHex = hex.slice(0, 1);
    const gHex = hex.slice(1, 2);
    const bHex = hex.slice(2, 3);

    const r = parseInt(rHex + rHex, 16);
    const g = parseInt(gHex + gHex, 16);
    const b = parseInt(bHex + bHex, 16);

    return [r, g, b];
  }
}

export function currency(currency: string) {
  return currency == "USD" ? "$" : currency;
}

export function capitalizeName(string: string) {
  const exceptions = ["of", "and", "the", "in", "on", "at"];

  return string
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index === 0 || !exceptions.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(" ");
}

export function isStringNumeric(string: string): boolean {
  if (string == "") return false;

  const toNumber = Number(string);
  return !Number.isNaN(toNumber);
}

export function isStringPositiveNumber(string: string): boolean {
  if (string == "") return false;

  const toNumber = Number(string);
  return toNumber > 0 && !Number.isNaN(toNumber);
}

export function underscoreToWords(input: string): string {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function isVideoUrl(url: string) {
  // List of video file extensions
  const videoExtensions = ["mp4", "webm", "ogg", "mov", "avi", "wmv", "flv", "mkv"];

  // Check if the URL ends with a video extension
  const urlLower = url.toLowerCase();
  if (videoExtensions.some((ext) => urlLower.endsWith("." + ext))) {
    return true;
  }

  return false;
}
