import useSWR from "swr";
import { createClient } from "@/lib/supabase/client";
import { Tables } from "@/types";
import useSWRImmutable from "swr/immutable";

export default function useBrands() {
  const { data, error, isLoading } = useSWRImmutable("brands", async () => {
    const { data, error } = await createClient().from("brands").select("*");

    if (error) throw error;
    return data;
  });

  return {
    data: (data || []) as Tables<"brands">[],
    error,
    isLoading,
  };
}
