import useSWR from "swr";

import consts from "@/config/consts";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import { Tables } from "../types";

interface Config {
  fields?: string;
  page?: number;
  limit?: number;
  search?: string;
  count?: "exact" | "planned" | "estimated";
  adminTenantId?: string;
  parentId?: string | null;
}

export default function useCategories() {
  const { data, error, isLoading } = useSWR("categories", async () => {
    const { data, error } = await createClient().from("products_categories").select("*");

    if (error) throw error;
    return data;
  });

  return {
    data: data || [],
    error,
    isLoading,
  };
}
/*export default function useCategories<Table = Tables<"products_categories">>({
  fields = "*",
  page = 1,
  limit = consts.PAGE_SIZE,
  search,
  count = "estimated",
  adminTenantId,
  parentId,
}: Config = {}) {
  const tenantId = useStoreState((state) => state.tenant?.id);
  const effectiveTenantId = adminTenantId || tenantId;
  const supabase = createClient();
  const fetcher = async () => {
    let query = supabase
      .from("products_categories")
      .select(fields, { count })
      .or(`tenant_id.eq.${effectiveTenantId},tenant_id.is.null`)
      .is("deleted_at", null);

    if (parentId === null) {
      query = query.is("parent_id", null);
    } else if (parentId) {
      query = query.eq("parent_id", parentId);
    }

    if (search?.trim()) {
      query = query.or(`name.ilike.%${search}%,description.ilike.%${search}%`);
    }

    if (page && limit) {
      const start = (page - 1) * limit;
      const end = page * limit - 1;
      query = query.range(start, end);
    }

    const { data, error, count: resultCount } = await query;
    if (error) throw error;

    return { data: data as Table[], count: resultCount || 0 };
  };

  const { data, error, isLoading } = useSWR(
    effectiveTenantId ? [`categories`, effectiveTenantId, page, search, parentId] : null,
    fetcher
  );

  return {
    categories: data?.data || [],
    categoriesCount: data?.count || 0,
    loading: isLoading,
    error,
  };
}
*/
