"use client";

import axios from "axios";
import { createClient } from "@/lib/supabase/client";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { Tables } from "@/types";
import { v4 as uuidv4 } from "uuid";
import { ok, err, Result } from "neverthrow";
import { generateSizeAllocations } from "@/lib/utils/cartUtils";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import { JoinedCart, AddToCartParams } from "@/types/supabase-custom/JoinedCart";
import consts from "@/config/consts";
import { PostgrestError } from "@supabase/supabase-js";
import useSWR from "swr";
import { MutableRefObject } from "react";
import { CanvaRef } from "@/components/canva/Canva";
import { dataURLToFile } from "@/lib/utils/fileUtils";
import { keys } from "lodash";
import { useStoreState } from "@/hooks/storeHooks";
import EventTracker from "@/lib/event-tracking/front-end/eventTrackingManager";
import { CanvaData } from "@/components/canva/ICanva";
import { calculateLineItemPrice, calculateMOQ, calculateSingleItemImpact } from "@/lib/utils/priceModifierUtils";
import setLocalStorage from "@/helpers/local-storage/setLocalStorage";
import { ColorMetadata } from "@/types/ColorCustomizationType";
const supabaseUtils = new SupabaseUtils(createClient());
export async function getProductData(productHandle: string): Promise<Tables<"products"> | null> {
  const client = createClient();
  const query = await client
    .from("products")
    .select("*, price_lists(*, price_lists_components(*))")
    .eq("handle", productHandle)
    .single();

  if (query.error) {
    return null;
  }
  return query.data;
}

export async function getOrdersForProfile(profileId: number): Promise<Tables<"orders">[] | null> {
  const client = createClient();
  const query = await client.from("orders").select("*").eq("profile_id", profileId);

  console.log("orders for profile query", query);

  if (query.error) {
    return null;
  }
  return query.data;
}

export function useProduct<Table = Tables<"products">>(productHandle: string, fields = "*", tenantId: string) {
  const {
    data = null,
    isLoading,
    error,
  } = useSWR(
    ["product", productHandle],
    async () => {
      const result = await supabaseUtils.getProductByHandle<Table>(productHandle, fields, tenantId);
      if (result.isErr()) throw new Error(result.error.message);
      return result.value;
    },
    { revalidateOnFocus: false }
  );

  return { data, isLoading, error };
}

export async function generateOrientedCustomImages(
  orientationRefs: MutableRefObject<{
    [key: string]: CanvaRef;
  }>
) {
  const orientedCustomImages: { [key: string]: string } = {};
  for (const orientation of keys(orientationRefs.current)) {
    const uploadResult = await generateCustomImage(orientation);
    if (uploadResult.isOk()) {
      orientedCustomImages[`${orientation}_custom_image_url`] = uploadResult.value;
    }
  }
  return orientedCustomImages;

  async function generateCustomImage(orientation: string) {
    // a. get image data from Canva
    const customImageData = await orientationRefs.current?.[orientation]?.getImageData();
    if (!customImageData) {
      return err({ errorMessage: `Failed to get customized image URL from Canva` });
    }

    // b. upload the image to Supabase
    const uploadResult = await supabaseUtils.uploadImage(
      "artworks",
      dataURLToFile(customImageData, `${orientation}.jpg`)
    );
    if (uploadResult.isErr()) {
      return err(uploadResult.error);
    }

    return ok(`${consts.STORAGE_BASE_URL}/${uploadResult.value.fullPath}`);
  }
}

export async function addToCart({
  product,
  configuration,
  cart,
  createCartThunk,
  addCartItemThunk,
  fetchCartThunk,
  tenantId,
  productModifiers,
  tenantStripeAccountId,
  isBlank = false,
  quantity = 0,
  size,
}: AddToCartParams): Promise<Result<JoinedCart, any>> {
  if (cart) {
    const lineItemExists = cart.line_items.find((item) => item.configurations.uuid === configuration.uuid);

    const blankLineItemExists = cart.line_items.find(
      (item) =>
        isBlank &&
        item.is_blank &&
        item.configurations.products.id === product.id &&
        (item.configurations.color_metadata as any).colorCode === (configuration.color_metadata as any).colorCode
    );

    if (lineItemExists) {
      return ok(cart);
    }

    if (blankLineItemExists) {
      return err({ message: "You already added a blank version of this product." });
    }

    const createStripeProductResult = await axios.post(`/api/payments/stripe/create-stripe-product`, {
      name: product.name,
      stripeAccount: tenantStripeAccountId,
    });

    const itemResult = await addCartItemThunk({
      cart_id: cart.id,
      configuration_id: configuration.id,
      size_allocations: generateSizeAllocations(
        product || [],
        // calculateMOQ(product, configuration, productModifiers) * quantity,
        quantity || calculateMOQ(product, configuration, productModifiers),
        isBlank,
        size || null,
        
      ),
      price: calculateLineItemPrice(product, configuration, productModifiers),
      stripe_product_reference: createStripeProductResult.data.product.id,
      stripe_tax_code: consts.STRIPE_TAX_CODE,
      tenant_id: tenantId || "",
      price_details: calculateSingleItemImpact(product, configuration, productModifiers),
      is_blank: isBlank,
    
    });

    if (itemResult.isErr()) {
      return err(itemResult.error);
    }

    const updatedCartResult = await fetchCartThunk({ id: cart.id, tenantId });
    if (updatedCartResult.isErr()) {
      return err(updatedCartResult.error);
    }

    return ok(updatedCartResult.value);
  } else {
    const cartResult = await createCartThunk({ total_price: 0, uuid: uuidv4(), tenant_id: tenantId });

    if (cartResult.isErr()) {
      return err(cartResult.error);
    }

    const createStripeProductResult = await axios.post(`/api/payments/stripe/create-stripe-product`, {
      name: product.name,
      stripeAccount: tenantStripeAccountId,
    });

    const itemResult = await addCartItemThunk({
      cart_id: cartResult.value.id,
      configuration_id: configuration.id,
      size_allocations: generateSizeAllocations(
        product || [],
        quantity || calculateMOQ(product, configuration, productModifiers),
        // calculateMOQ(product, configuration, productModifiers) * quantity,
        isBlank,
        size || null
      ),
      price: calculateLineItemPrice(product, configuration, productModifiers) * quantity,
      stripe_product_reference: createStripeProductResult.data.product.id,
      stripe_tax_code: consts.STRIPE_TAX_CODE,
      tenant_id: tenantId,
      price_details: calculateSingleItemImpact(product, configuration, productModifiers),
      is_blank: isBlank,
    });

    if (itemResult.isOk()) {
      const lineItem = itemResult.value.line_items.find((item) => item.configurations.uuid === configuration.uuid);
      lineItem && EventTracker.addToCart(lineItem);
    }

    if (itemResult.isErr()) {
      return err(itemResult.error);
    }

    return ok(itemResult.value);
  }
}
