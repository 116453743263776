"use client";

import { useStoreState } from "src/hooks/storeHooks";
import useSWR from "swr";
import store from "../store";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { createClient } from "@/lib/supabase/client";
import ProductsGrid from "@/components/studios/ProductsGrid";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import { joinedProductFields, ProductStatus } from "@/lib/utils/productUtils";
import { useState, useMemo } from "react";
import FilterSidebar from "../../components/studios/FilterSidebar";
import { Tables } from "../../types";
import { LoadingLabel } from "../../components/ui/loading-label";
import debounce from "lodash/debounce";
import useSWRImmutable from "swr/immutable";
import { cn } from "../../lib/utils/stylesUtils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "../../components/ui/button";

export default function StudioPage() {
  const tenant = useStoreState((state) => state.tenant);
  const tenantId = tenant?.id || "";

  // Add filter states
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [selectedSizes, setSelectedSizes] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 1000]);

  // Add debounced price range state
  const [debouncedPriceRange, setDebouncedPriceRange] = useState<[number, number]>([0, 1000]);

  // Create debounced handler for price range
  const debouncedSetPriceRange = useMemo(
    () => debounce((value: [number, number]) => setDebouncedPriceRange(value), 500),
    []
  );

  // Update price range handler to use both immediate and debounced states
  const handlePriceRangeChange = (value: [number, number]) => {
    setPriceRange(value); // immediate update for UI
    debouncedSetPriceRange(value); // debounced update for query
  };

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const { isLoading: isMinMaxLoading } = useSWRImmutable(["minMaxData", tenantId], async () => {
    const query = createClient()
      .from("products")
      .select(
        `
        thresholds->0->price::float
      `
      )
      .eq("tenant_id", tenantId)
      .is("deleted_at", null)
      .eq("status", ProductStatus.enabled);

    const result = await query;

    if (result.error) {
      console.error("Error fetching min/max prices:", result.error);
      return { min: 0, max: 1000 }; // fallback values
    }

    const prices = result.data.map((row: any) => row.price).filter((price: number) => price != null);

    const _minPrice = Math.floor(Math.min(...prices));
    const _maxPrice = Math.ceil(Math.max(...prices));

    setMinPrice(_minPrice);
    setMaxPrice(_maxPrice);
    setDebouncedPriceRange([_minPrice, _maxPrice]);
  });

  const { data: products = [], isLoading: isProductsLoading } = useSWRImmutable(
    minPrice == 0 && maxPrice == 0
      ? null
      : ["products", selectedCategories, selectedBrands, selectedColors, selectedSizes, debouncedPriceRange],
    async () => {
      if (tenantId) {
        const query = createClient()
          .from("products")
          .select(joinedProductFields)
          .eq("tenant_id", tenantId)
          .eq("status", ProductStatus.enabled)
          .is("deleted_at", null);

        // Apply filters at database level
        if (selectedCategories.length > 0) {
          query.in("category_id", selectedCategories);
        }
        if (selectedBrands.length > 0) {
          query.in("brand_id", selectedBrands);
        }
        if (selectedColors.length > 0) {
          // Use overlap operator (@>) with the main_color enum values
          query.overlaps(
            "main_colors",
            selectedColors.map((v) => v.toLowerCase())
          );
        }
        if (selectedSizes.length > 0) {
          // Assuming sizes is stored as a JSON array in the database
          query.contains("sizes", selectedSizes);
        }

        // Update price filter to use thresholds[0].price

        query.gte("thresholds->0->price", debouncedPriceRange[0]);
        query.lte("thresholds->0->price", debouncedPriceRange[1]);

        const result = await query;

        if (result.error) {
          store.getActions().setError({
            code: "" + result.error.code,
            message: "" + result.error.message,
          });
          return [];
        }

        return result.data;
      }
      return [];
    }
  );

  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const isLoading = isMinMaxLoading || isProductsLoading || isFakeLoading;

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

  return (
    <div className={cn("flex flex-col sm:flex-row gap-6 p-4")}>
      <div>
        <div
          className={cn(
            "flex-1 sm:hidden text-center fixed top-12 bg-white z-50 w-full left-0 right-0 h-10 p-1"
            //isFilterSidebarOpen && "p-2"
          )}
        >
          <Button
            onClick={() => {
              if (isFilterSidebarOpen) {
                setIsFakeLoading(true);
                setTimeout(() => {
                  setIsFakeLoading(false);
                }, 250);
              }
              setIsFilterSidebarOpen(!isFilterSidebarOpen);
            }}
            variant={isFilterSidebarOpen ? "default" : "link"}
            className={cn(isFilterSidebarOpen && "w-full h-full")}
          >
            {isFilterSidebarOpen ? (
              <>Apply Filters</>
            ) : (
              <>
                <ChevronDown className="w-4 h-4" />
                Filters
              </>
            )}
          </Button>
        </div>

        <FilterSidebar
          products={products as JoinedProduct[]}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          selectedColors={selectedColors}
          setSelectedColors={setSelectedColors}
          selectedSizes={selectedSizes}
          setSelectedSizes={setSelectedSizes}
          priceRange={priceRange}
          setPriceRange={handlePriceRangeChange}
          availablePriceRange={[minPrice, maxPrice]}
          className={cn(
            "sticky top-20 mt-4 bg-gray-50 border-gray-100 rounded-lg p-4 h-fit",
            !isFilterSidebarOpen && "hidden sm:block",
            isFilterSidebarOpen && "w-full mt-10"
          )}
        />
      </div>

      {!isLoading ? (
        <>
          {products.length > 0 && (
            <>
              <div className={cn("flex-1 max-w-6xl mx-auto sm:mx-0", isFilterSidebarOpen && "hidden sm:block")}>
                <ProductsGrid products={products as JoinedProduct[]} />
              </div>
            </>
          )}
          {products.length == 0 && (
            <div
              className={cn(
                "flex flex-col items-center justify-center h-full mt-4",
                isFilterSidebarOpen && "hidden sm:block"
              )}
            >
              <p className="text-muted-foreground text-sm">No products found.</p>
            </div>
          )}
        </>
      ) : (
        <>
          <LoadingLabel
            loading={true}
            className={cn("mt-4 mx-auto sm:mx-0", isFilterSidebarOpen && "hidden sm:block")}
          />
        </>
      )}
    </div>
  );
}
