"use client";

import { Slider } from "@/components/ui/slider";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import useCategories from "@/hooks/useCategories";
import useBrands from "@/hooks/useBrands";
import { cn } from "@/lib/utils/stylesUtils";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { useEffect, useMemo, useState } from "react";
import CheckboxList from "@/components/ui/CheckboxList";
import { DualRangeSlider } from "../ui/dual-range-slider";
import { formatCurrency } from "../../lib/utils/n";

interface FilterSidebarProps {
  products: JoinedProduct[];
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  selectedBrands: string[];
  setSelectedBrands: (brands: string[]) => void;
  selectedColors: string[];
  setSelectedColors: (colors: string[]) => void;
  selectedSizes: string[];
  setSelectedSizes: (sizes: string[]) => void;
  priceRange: [number, number];
  setPriceRange: (range: [number, number]) => void;
  className?: string;
  availablePriceRange: [number, number];
}

const MAIN_COLORS = [
  { name: "Red", value: "rgb(217,35,22)" },
  { name: "Blue", value: "rgb(28,34,189)" },
  { name: "Green", value: "rgb(28,189,60)" },
  { name: "Yellow", value: "rgb(245,252,20)" },
  { name: "Black", value: "rgb(0, 0, 0)" },
  { name: "White", value: "rgb(255, 255, 255)" },
];

const AVAILABLE_SIZES = ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];

export default function FilterSidebar({
  products,
  selectedCategories,
  setSelectedCategories,
  selectedBrands,
  setSelectedBrands,
  selectedColors,
  setSelectedColors,
  selectedSizes,
  setSelectedSizes,
  priceRange,
  setPriceRange,
  className,
  availablePriceRange,
}: FilterSidebarProps) {
  const { data: categories } = useCategories();
  const { data: brands } = useBrands();

  useEffect(() => {
    if (priceRange[0] < availablePriceRange[0] || priceRange[1] > availablePriceRange[1]) {
      setPriceRange(availablePriceRange);
    }
  }, [availablePriceRange]);

  return (
    <div className={cn("w-64 flex-shrink-0 pr-4", className)}>
      <div className="space-y-6">
        {/* Price Range Filter */}
        {availablePriceRange &&
          availablePriceRange.length == 2 &&
          availablePriceRange[0] != 0 &&
          availablePriceRange[1] != 0 && (
            <div className="space-y-4">
              <Label className="font-bold">Price Range</Label>
              <div className="px-2">
                <DualRangeSlider
                  min={availablePriceRange[0]}
                  max={availablePriceRange[1]}
                  step={1}
                  value={[priceRange[0], priceRange[1]]}
                  onValueChange={(value) => setPriceRange(value as [number, number])}
                  className="mt-8"
                  label={(value) => (
                    <span className="text-muted-foreground text-sm">{formatCurrency(value || 0, "USD", 0)}</span>
                  )}
                />
              </div>
            </div>
          )}

        {/* Brands Filter */}
        <div className="space-y-2">
          <Label className="font-bold">Brand</Label>
          <CheckboxList
            options={brands.map((brand: any) => ({ label: brand.name, value: brand.id }))}
            selectedValues={selectedBrands}
            onChange={setSelectedBrands}
          />
        </div>

        {/* Categories Filter */}
        <div className="space-y-2">
          <Label className="font-bold">Categories</Label>
          <CheckboxList
            options={categories.map((category) => ({ label: category.name, value: category.id }))}
            selectedValues={selectedCategories}
            onChange={setSelectedCategories}
          />
        </div>

        {/* Sizes Filter */}
        <div className="space-y-2">
          <Label className="font-bold">Sizes</Label>
          <div className="grid grid-cols-4 gap-2">
            {AVAILABLE_SIZES.map((size) => (
              <div
                key={size}
                onClick={() => {
                  setSelectedSizes(
                    selectedSizes.includes(size) ? selectedSizes.filter((s) => s !== size) : [...selectedSizes, size]
                  );
                }}
                className={cn(
                  "flex items-center justify-center h-8 bg-white rounded-md cursor-pointer hover:bg-accent/5 transition-colors",
                  selectedSizes.includes(size) ? "border-2 border-primary" : "border border-input"
                )}
              >
                <span className="text-sm leading-none">{size}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Colors Filter */}
        <div className="space-y-2">
          <Label className="font-bold">Colors</Label>
          <div className="flex flex-wrap gap-2">
            {MAIN_COLORS.map((color) => (
              <div
                key={color.value}
                onClick={() => {
                  setSelectedColors(
                    selectedColors.includes(color.name)
                      ? selectedColors.filter((c) => c !== color.name)
                      : [...selectedColors, color.name]
                  );
                }}
                className={cn(
                  "w-6 h-6 rounded-full cursor-pointer border relative border-gray-200 hover:border-2",
                  selectedColors.includes(color.name) && "ring-2 ring-black ring-offset-2"
                )}
                style={{ backgroundColor: color.value }}
                title={color.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
