import { Tables } from "@/types";
import ProductItem from "./ProductItem";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import { BreadcrumbProp } from "@/components/ui/breadcrumbs";

interface Props {
  products: JoinedProduct[];
  relativeBreadcrumbs?: BreadcrumbProp[];
}

function NewProductsGrid({ products, relativeBreadcrumbs }: Props) {
  return (
    <ul className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 m-4 ml-0"}>
      {products
        .filter((product) => (product.colors?.length as any) > 0 && product.handle)
        .map((product, index) => (
          <li key={product.id}>
            <ProductItem key={index} product={product} relativeBreadcrumbs={relativeBreadcrumbs} />
          </li>
        ))}
    </ul>
  );
}

export default NewProductsGrid;
