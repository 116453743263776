import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils/stylesUtils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { uniqueId } from "lodash";
import { Label } from "./label";

interface CheckboxListProps {
  options: Array<{ label: string; value: any }>;
  selectedValues: any[];
  onChange: (values: any[]) => void;
  className?: string;
}

export default function CheckboxList({ options, selectedValues, onChange, className }: CheckboxListProps) {
  const [showAll, setShowAll] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);

  // Sort options into selected and unselected
  const sortedOptions = [...options].sort((a, b) => {
    const aSelected = selectedValues.includes(a.value);
    const bSelected = selectedValues.includes(b.value);
    if (aSelected === bSelected) return 0;
    return aSelected ? -1 : 1;
  });

  const displayedOptions = showAll ? sortedOptions : sortedOptions.slice(0, 4);

  const handleChange = (checked: boolean, value: any) => {
    onChange(checked ? [...selectedValues, value] : selectedValues.filter((v) => v !== value));
  };

  const handleShowMoreClick = () => {
    setIsExpanding(true);
    setShowAll(!showAll);
    // Reset the expanding state after animation would have completed
    setTimeout(() => setIsExpanding(false), 300);
  };

  return (
    <div className={cn("space-y-2 relative", className)}>
      <div className="flex flex-col space-y-1">
        {/* First 4 items always visible */}
        {sortedOptions.slice(0, 4).map((option, index) => (
          <motion.div
            key={option.value}
            layout={!isExpanding}
            layoutId={option.value}
            transition={{ duration: 0.2 }}
            className={cn("flex items-center space-x-2 hover:underline cursor-pointer")}
            onClick={() => handleChange(!selectedValues.includes(option.value), option.value)}
          >
            <Checkbox
              id={uniqueId("checkbox-")}
              checked={selectedValues.includes(option.value)}
              onCheckedChange={(checked) => handleChange(!!checked, option.value)}
            />
            <label
              htmlFor={uniqueId("checkbox-")}
              className={cn(
                "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer",
                selectedValues.includes(option.value) ? "font-bold" : "font-normal"
              )}
            >
              {option.label}
            </label>
          </motion.div>
        ))}

        {/* Additional items that slide down */}
        <AnimatePresence>
          {showAll && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="space-y-1"
            >
              {sortedOptions.slice(4).map((option, index) => (
                <motion.div
                  key={option.value}
                  layout={!isExpanding}
                  layoutId={option.value}
                  transition={{ duration: 0.2 }}
                  className={cn("flex items-center space-x-2 hover:underline cursor-pointer")}
                  onClick={() => handleChange(!selectedValues.includes(option.value), option.value)}
                >
                  <Checkbox
                    id={uniqueId("checkbox-")}
                    checked={selectedValues.includes(option.value)}
                    onCheckedChange={(checked) => handleChange(!!checked, option.value)}
                  />
                  <label
                    htmlFor={uniqueId("checkbox-")}
                    className={cn(
                      "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer",
                      selectedValues.includes(option.value) ? "font-bold" : "font-medium"
                    )}
                  >
                    {option.label}
                  </label>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {options.length > 4 && (
        <>
          {/*!showAll && (
            <div className="absolute bottom-4 left-0 right-0 h-16 bg-gradient-to-t from-background to-transparent pointer-events-none" />
          )*/}
          <button
            onClick={handleShowMoreClick}
            className={cn(
              "text-sm hover:underline w-full flex items-center justify-center gap-1 text-muted-foreground"
              //!showAll && "absolute bottom-0 left-0 right-0 z-10"
            )}
          >
            <span>{showAll ? "Show " + (options.length - 4) + " less" : "Show " + (options.length - 4) + " more"}</span>
            {showAll ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </button>
        </>
      )}
    </div>
  );
}
